import { ContainerMaterialDensity } from '#generated-types';

export const containerMaterialDensities: Array<{
  id: ContainerMaterialDensity;
  name: string;
}> = [
  {
    id: ContainerMaterialDensity.High,
    name: 'High density (e.g. water, sand, turnings)',
  },
  {
    id: ContainerMaterialDensity.Medium,
    name: 'Medium density (e.g. shredded material)',
  },
  {
    id: ContainerMaterialDensity.Low,
    name: 'Low density (e.g. plates, boulders)',
  },
];
