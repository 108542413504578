import { createParser } from 'nuqs';

export const createFilterParser = <T>() =>
  createParser<Array<{ filterBy: T; value: string }>>({
    parse(queryValue: string) {
      if (!queryValue) {
        return null;
      }

      return decodeURIComponent(queryValue)
        .split('|')
        .map((v) => {
          const [filterBy, value] = v.split(':');
          return { filterBy: filterBy as T, value };
        });
    },
    serialize(value) {
      if (!value?.length) {
        return null as unknown as string;
      }

      return encodeURIComponent(
        value.map((v) => `${v.filterBy}:${v.value}`).join('|'),
      );
    },
  });
