import { ContainerMaterialConsistency } from '#generated-types';

export const containerMaterialConsistencies: Array<{
  id: ContainerMaterialConsistency;
  name: string;
}> = [
  {
    id: ContainerMaterialConsistency.SmallSizeConsistentDensity,
    name: 'Small size, consistent density (e.g. sand, turnings, sludge)',
  },
  {
    id: ContainerMaterialConsistency.SmallSizeVariableDensity,
    name: 'Small size, variable density (e.g. shredded material)',
  },
  {
    id: ContainerMaterialConsistency.LargeSizeConsistentDensity,
    name: 'Large size, consistent density (e.g. bent rebar, general scrap)',
  },
  {
    id: ContainerMaterialConsistency.LargeSizeVariableDensity,
    name: 'Large size, variable density (e.g. C&D, steel plates, pipes)',
  },
];
