import { useCallback } from 'react';

import { red60 } from '@carbon/colors';
import { Box, Unlink, ProgressBar } from '@carbon/icons-react';
import { IconButton, Loading, Modal } from '@carbon/react';
import { ModalStateManager } from '@wastewizer/ui-components';

import { useUnAssignBinBarMutation } from './_generated';
import { ContainerSiteTableRowFragment } from '../_generated';

export type UnAssignBinBarProps = {
  containerSite: ContainerSiteTableRowFragment;
  afterUnAssign: () => void;
};

export const UnAssignBinBar: React.FunctionComponent<UnAssignBinBarProps> = ({
  containerSite,
  afterUnAssign,
}) => {
  const [unAssignBinBar, { loading: unAssigning }] =
    useUnAssignBinBarMutation();

  const renderLauncher = useCallback(
    ({ setOpen }) => (
      <IconButton
        label="Remove BinBar"
        align="top-right"
        kind="ghost"
        size="sm"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Unlink />
      </IconButton>
    ),
    [],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => (
      <Modal
        open={open}
        preventCloseOnClickOutside
        modalLabel="Remove BinBar"
        modalHeading={
          <p>
            {' '}
            Are you sure you want to remove{' '}
            <strong>{containerSite.binBar.name}</strong> from{' '}
            <strong>{containerSite.name}</strong>
          </p>
        }
        danger
        primaryButtonText="Remove"
        secondaryButtonText="Cancel"
        onRequestClose={() => {
          setOpen(false);
        }}
        onRequestSubmit={async () => {
          await unAssignBinBar({
            variables: {
              id: containerSite.id,
            },
          });

          setOpen(false);
          afterUnAssign();
        }}
      >
        <>
          <Loading withOverlay active={unAssigning} />
          <div className="ww--confirm_modal-body_content">
            <Box size={80} />
            <Unlink color={red60} size={40} />
            <ProgressBar size={80} />
          </div>
          <div className="ww--confirm_modal-body_footer">
            A container site without an assigned BinBar will not be monitored
            and will <strong>stop recording new data</strong> (even if a BinBar
            is physically present).
          </div>
        </>
      </Modal>
    ),
    [containerSite, unAssignBinBar, unAssigning, afterUnAssign],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey={`unassign-binbar-${containerSite.id}`}
    />
  );
};
