import { ContainerLoadingMethod } from '#generated-types';

export const containerLoadingMethods: Array<{
  id: ContainerLoadingMethod;
  name: string;
}> = [
  {
    id: ContainerLoadingMethod.SingleSource,
    name: 'Single source (e.g. chute, conveyor',
  },
  {
    id: ContainerLoadingMethod.SingleSide,
    name: 'Single side (e.g. loading dock)',
  },
  {
    id: ContainerLoadingMethod.MultiSide,
    name: 'Multiple sides',
  },
];
