import { ContainerGroundType } from '#generated-types';

export const containerGroundTypes: Array<{
  id: ContainerGroundType;
  name: string;
}> = [
  {
    id: ContainerGroundType.HardSurface,
    name: 'Hard surface (e.g. cement, asphalt)',
  },
  {
    id: ContainerGroundType.GravelSurface,
    name: 'Gravel',
  },
  {
    id: ContainerGroundType.SoftSurface,
    name: 'Soft surface (e.g. dirt, grass)',
  },
];
